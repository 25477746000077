import React, { useEffect, useState } from 'react';
import Layout from '../../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';

import AboutUs from '../../components/AboutUs/AboutUs';
import ContactUs from '../../components/ContactUs/ContactUs';
import Dropdown, {
	DropdownOptionType
} from '../../components/Dropdown/Dropdown';
import Search from '../../components/Search/Search';
import Pagination from '../../components/Pagination/Pagination';

import searchHero from '../../images/search-page-hero.png';

import transdevLogo from '../../images/transdev-logo-red.png';
import transdevLogoFrench from '../../images/transdev-logo-red-french.png';
import locationIcon from '../../images/location-icon.svg';
import arrowRight from '../../images/arrow-right-w.svg';
import { getTranslatedString } from '../../i18n/utils';

let isFrench = process.env.GATSBY_LANG === 'FR';

let jobs: Job[] = [];

const SearchPage = () => {
	let data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`)?.allSitePage?.edges;

	const indexes = [
		{ name: 'title', factor: 4 },
		{ name: 'description', factor: 1 },
		{ name: 'city', factor: 2 },
		{ name: 'reqNumber', factor: 6 },
		{ name: 'state', factor: 2 },
		{ name: 'postalCode', factor: 3 }
	];

	// States
	const [filteredJobs, setFilteredJobs] = useState<Job[] | []>(jobs);
	const [displayedJobs, setDisplayedJobs] = useState<Job[] | []>(jobs);
	const [cities, setCities] = useState<DropdownOptionType[]>([]);
	const [provinces, setProvinces] = useState<DropdownOptionType[]>([]);
	const [categories, setCategories] = useState<DropdownOptionType[]>([]);
	const [jobTypes, setJobTypes] = useState<DropdownOptionType[]>([]);
	const [filters, setFilters] = useState<{
		division?: DropdownOptionType[];
		city?: DropdownOptionType[];
		category?: DropdownOptionType[];
		jobType?: DropdownOptionType[];
		province?: DropdownOptionType[];
	}>({});
	const [term, setTerm] = useState<string>('');

	const populateDropdown = (
		data: Object[],
		value: string,
		setState: Function
	) => {
		let values: DropdownOptionType[] = [];
		data.forEach((job: any) => {
			if (job.hasOwnProperty(value)) {
				if (!values.find((_value) => _value.value === job[value])) {
					values.push({
						text: isFrench
							? getTranslatedString(job[value])
							: job[value],
						value: job[value],
						checked: false
					});
				}
			}
		});
		values.sort((a, b) => a.text.localeCompare(b.text));
		setState(values);
	};

	const handleFilterChange = (
		filter: string,
		selectedOptions: DropdownOptionType[] | []
	) => {
		let _filters: any = { ...filters };
		_filters[filter] = selectedOptions;
		setFilters(_filters);
		sessionStorage.setItem('filters', JSON.stringify(_filters));

		const url = new URL(window.location.href);

		if (filter === 'division') {
			url.searchParams.delete('entity');
		} else if (filter === 'category') {
			url.searchParams.delete('department');
		} else {
			url.searchParams.delete(filter);
		}

		selectedOptions.forEach((opt) => {
			if (opt) {
				if (filter === 'division') {
					if (!url.searchParams.has('entity', opt.value.toString())) {
						url.searchParams.append('entity', opt.value.toString());
					}
				} else if (filter === 'category') {
					if (
						!url.searchParams.has(
							'department',
							opt.value.toString()
						)
					) {
						url.searchParams.append(
							'department',
							opt.value.toString()
						);
					}
				} else {
					if (!url.searchParams.has(filter, opt.value.toString())) {
						url.searchParams.append(filter, opt.value.toString());
					}
				}
			}
		});

		history.pushState({}, '', url);
	};

	useEffect(() => {
		const url = new URL(window.location.href);

		// Check if there are no search params in the URL
		if (!url.search) {
			// Retrieve filters from sessionStorage
			const savedFiltersJSON = sessionStorage.getItem('filters');
			const savedFilters = savedFiltersJSON
				? JSON.parse(savedFiltersJSON)
				: {};

			// Populate search params based on saved filters
			for (const filter in savedFilters) {
				const options = savedFilters[filter];
				options.forEach((opt: any) => {
					if (opt && opt.value) {
						if (filter === 'category') {
							url.searchParams.append(
								'department',
								opt.value.toString()
							);
						} else if (filter === 'division') {
							url.searchParams.append(
								'entity',
								opt.value.toString()
							);
						} else {
							url.searchParams.append(
								filter,
								opt.value.toString()
							);
						}
					}
				});
			}

			// Update the URL and history
			history.pushState({}, '', url);
		}
	}, []);

	useEffect(() => {
		const url = new URL(window.location.href);
		const division = url.searchParams.getAll('entity');
		const term = url.searchParams.get('term');
		const city = url.searchParams.getAll('city');
		const province = url.searchParams.getAll('province');
		const category = url.searchParams.getAll('department');
		const jobType = url.searchParams.getAll('type');

		if (term) {
			setTerm(term);
		}

		let _filters: any = { ...filters };

		if (division) {
			division.forEach((param) => {
				if (!_filters.division) {
					_filters.division = [];
				}
				_filters.division.push({
					text: param,
					value: param
				});
			});
		}

		if (city) {
			city.forEach((param) => {
				if (!_filters.city) {
					_filters.city = [];
				}
				_filters.city.push({
					text: param,
					value: param
				});
			});
		}

		if (province) {
			province.forEach((param) => {
				if (!_filters.province) {
					_filters.province = [];
				}
				_filters.province.push({
					text: param,
					value: param
				});
			});
		}

		if (category) {
			category.forEach((param) => {
				if (!_filters.category) {
					_filters.category = [];
				}
				_filters.category.push({
					text: param,
					value: param
				});
			});
		}

		if (jobType) {
			jobType.forEach((param) => {
				if (!_filters.jobType) {
					_filters.jobType = [];
				}
				_filters.jobType.push({
					text: param,
					value: param
				});
			});
		}

		setFilters(_filters);
		sessionStorage.setItem('filters', JSON.stringify(_filters));
	}, []);

	useEffect(() => {
		let _tempJobs: Object[] = [];
		// Search Term
		let _term = term.toLowerCase();
		let terms: string[] = [];
		if (_term !== '') {
			// Replace punctuating between words with spaces.
			_term = _term.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ' ');

			// Split search words, delimited by spaces
			terms = _term.split(' ');

			// Remove any terms that are empty strings. ""
			terms = terms.filter((e) => e !== '');
		}

		// Query Jobs
		jobs.forEach((job) => {
			let addJob = true;
			// Filters
			for (const [key, _filters] of Object.entries(filters)) {
				if (job.hasOwnProperty(key)) {
					if (
						_filters.find(
							(filter) => filter.value === (job as any)[key]
						)
					) {
						addJob = true;
					} else if (_filters.length === 0) {
						addJob = true;
					} else {
						addJob = false;
						break;
					}
				}
			}

			if (terms.length > 0) {
				let indexWeight = 0;
				let data = job;
				// For each index
				for (let index in indexes) {
					if (indexes.hasOwnProperty(index)) {
						// Check if the jobData has that index.
						if (data.hasOwnProperty(indexes[index].name)) {
							// Make sure all terms are included in that index.
							// let allTermsIncluded = false;

							// For each search term
							for (let _term in terms) {
								if (terms.hasOwnProperty(_term)) {
									// If the index includes the term.
									if (
										(data as any)[indexes[index].name]
											.toLowerCase()
											.includes(terms[_term])
									) {
										indexWeight =
											indexWeight +
											100 * indexes[index].factor;
									}
								}
							}
						}
					}
				}
				if (indexWeight > 0 && addJob) {
					data.weight = indexWeight;
					addJob = true;
				} else if (term === '' && addJob) {
					addJob = true;
				} else {
					addJob = false;
				}
			}
			if (addJob) {
				_tempJobs.push(job);
			}
		});

		setFilteredJobs(_tempJobs as Job[]);
	}, [filters, term]);

	useEffect(() => {
		// Re-organize the data.
		let _jobs = [];

		data = (data as any[]).filter((job) => {
			let isJobFrench = job?.node?.pageContext?.applicationFormUrl
				?.trim()
				.endsWith('/fr_CA');
			if (isFrench) {
				return isJobFrench;
			} else {
				return !isJobFrench;
			}
		});

		for (let job in data) {
			if (data[job]?.node?.pageContext) {
				if (data[job]?.node?.pageContext?.title) {
					_jobs.push(data[job].node.pageContext);
				}
			}
		}

		jobs = [..._jobs];
		setFilteredJobs(_jobs);

		// Populate dropdown fields.
		populateDropdown(_jobs, 'city', setCities);
		populateDropdown(_jobs, 'province', setProvinces);
		populateDropdown(_jobs, 'jobType', setJobTypes);
		populateDropdown(_jobs, 'category', setCategories);
	}, [data]);

	const entities: DropdownOptionType[] = [
		{ text: 'Transdev Canada', value: 'Transdev Canada', checked: false },
		{ text: 'Transdev Québec', value: 'Transdev Québec', checked: false },
		{ text: 'Transdev West', value: 'Transdev West', checked: false },
		{ text: 'Rail', value: 'Rail', checked: false }
	];

	return (
		<Layout
			title={'Transdev Careers'}
			desc={'Transdev Careers'}
			altLangPath="/search/"
		>
			<section className="bg-white">
				<div className="flex items-center justify-center bg-pri max-xl:flex-col-reverse">
					<div className="flex flex-col gap-2 px-14 pb-10 text-white max-md:px-6">
						<a
							className={
								'mb-10 inline-flex w-fit items-center gap-3 pb-2.5 text-xl font-medium text-white hover:opacity-90 max-xl:mt-6'
							}
							href={'/'}
						>
							<img src={arrowRight} className={'rotate-180'} />
							{isFrench ? 'Retour' : 'Back'}
						</a>
						<p className="text-base font-medium leading-tight">
							{isFrench ? "OFFRES D'EMPLOI" : 'JOB OFFERS'}
						</p>
						<h1 className="text-5xl font-bold">Transdev Canada</h1>
						<h1 className="text-xl leading-tight">
							{isFrench
								? `Vous souhaitez participer activement à la construction d’une mobilité innovante en rejoignant notre Groupe ? Renseignez-vous sur nos entreprises et obtenez des informations sur les offres d’emploi actuelles.`
								: `Would you like to take an active part in building
							innovative mobility by joining our Group? Find out
							about our businesses and obtain information on
							current job offers.`}
						</h1>
					</div>
					<img
						src={searchHero}
						width={657}
						height={371}
						alt="Search Hero"
						className="object-cover max-xl:max-h-[260px] max-xl:w-full"
					/>
				</div>
			</section>
			<section className={'container mx-auto bg-white pb-6 pt-12'}>
				<div className={'mx-auto px-14 max-md:px-6'}>
					<a href={isFrench ? '/generale/' : '/general/'}>
						<div className="flex w-full items-center justify-between gap-6 p-9 shadow-3xl max-md:flex-col">
							<div className="flex flex-col gap-2">
								<p className="text-[21px] font-bold leading-tight text-black max-md:text-center">
									{isFrench
										? 'Vous ne voyez pas un poste qui vous intéresse?'
										: 'Don’t see a position you are interested in?'}
								</p>
								<p className="text-[21px] font-medium leading-tight text-black max-md:text-center">
									{isFrench
										? "Soumettre une candidature spontanée à l'une de nos entités"
										: 'Submit a general application to one of our entities.'}
								</p>
							</div>
							<button
								className={
									'h-fit bg-pri px-10 py-3 text-xl font-normal text-white transition-all hover:bg-[#940021] hover:opacity-90 focus:opacity-90'
								}
							>
								{isFrench ? 'Postuler ici' : 'Apply Here'}
							</button>
						</div>
					</a>
				</div>
			</section>
			<section className={'container mx-auto bg-white py-10'}>
				<div className={'mx-auto px-14 max-md:px-6'}>
					<h3
						id="job-offers-title"
						className="my-8 text-[28px] font-bold leading-[0.8] text-black"
					>
						{isFrench ? "Offres d'emploi" : 'Job Offers'}
					</h3>
					<div
						className={
							'grid grid-cols-1 justify-center gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-7'
						}
					>
						<Search
							placeholder={isFrench ? 'Recherche' : 'Search'}
							onChange={(_term: string) => {
								setTerm(_term);
								const url = new URL(window.location.href);
								url.searchParams.delete('term');
								url.searchParams.append('term', _term);

								history.pushState({}, '', url);
							}}
							initValue={term}
						/>{' '}
						<Dropdown
							text={isFrench ? 'Entité' : 'Entity'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('division', e);
							}}
							multiple
							options={entities.sort((a, b) =>
								a.text.localeCompare(b.text)
							)}
							selected={filters?.division}
						/>
						<Dropdown
							text={isFrench ? 'Ville' : 'City'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('city', e);
							}}
							multiple
							options={cities}
							selected={filters?.city}
						/>
						<Dropdown
							text={isFrench ? 'Province' : 'Province'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('province', e);
							}}
							multiple
							options={provinces}
							selected={filters?.province}
						/>
						<Dropdown
							text={isFrench ? 'Département' : 'Department'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('category', e);
							}}
							options={categories}
							selected={filters?.category}
						/>
						<Dropdown
							text={isFrench ? "Type d'Emploi" : 'Job Type'}
							onChange={(e: DropdownOptionType[]) => {
								handleFilterChange('jobType', e);
							}}
							options={jobTypes}
							selected={filters?.jobType}
						/>
					</div>
				</div>
				<div
					className={
						'mx-auto mt-6 flex flex-col gap-6 px-14 max-md:px-6'
					}
				>
					{displayedJobs?.map((job) => {
						return (
							<a
								key={job.id}
								href={job.jobPath}
								className={[
									'flex w-full cursor-pointer flex-col items-center justify-between gap-4 rounded-lg bg-white p-4 py-5 text-left text-sm font-medium text-body shadow-3xl hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-300 lg:flex-row lg:px-12 lg:py-8'
								].join(' ')}
							>
								<div className="flex w-full gap-6 max-sm:flex-col">
									<img
										src={
											isFrench
												? transdevLogoFrench
												: transdevLogo
										}
										width={120}
										height={35}
										className="h-fit self-center"
									/>
									<div className={'flex flex-col gap-[14px]'}>
										<span
											className={
												'text-[26px] font-semibold leading-tight text-pri'
											}
										>
											{job.title}
										</span>
										<p
											className={
												'flex gap-2 text-[16px] leading-8 text-body'
											}
										>
											<img
												src={locationIcon}
												alt="Location Icon"
											/>
											{job.city}
										</p>
									</div>
								</div>
								<div
									className={
										'flex h-min w-full max-w-fit items-center justify-center gap-7 max-lg:mt-6 max-sm:mt-2 max-[480px]:flex-col'
									}
								>
									<span
										className={
											'h-min w-fit bg-[#F2F2F2] px-4 py-3 text-center text-xl font-normal text-black'
										}
									>
										{isFrench
											? getTranslatedString(job.category)
											: job.category}
									</span>
									<button
										className={
											'mx-auto h-min w-fit bg-pri px-4 py-3 text-xl font-normal text-white transition-all hover:bg-[#940021] hover:opacity-90 focus:opacity-90'
										}
									>
										{isFrench
											? 'Postuler ici'
											: 'Apply Here'}
									</button>
								</div>
							</a>
						);
					})}
					<Pagination
						data={filteredJobs}
						itemsPerPageDefault={25}
						onUpdateDisplayedItems={setDisplayedJobs}
					/>
				</div>
			</section>
			<section className="bg-white">
				<div className="container mx-auto w-full max-w-4xl px-4 pb-11 pt-10">
					<div className="flex flex-col gap-[28px]">
						<h3 className="text-[28px] font-bold leading-[0.8] text-black">
							{isFrench
								? 'À Propos de Transdev Canada'
								: 'About Transdev Canada'}
						</h3>
						<p className="text-2xl leading-snug">
							{isFrench
								? `Opérateur et intégrateur mondial de la mobilité, Transdev permet à chacun de se déplacer au quotidien grâce à des solutions sûres, efficaces et innovantes au service du bien commun. Transdev transporte en moyenne 11 millions de passagers chaque jour grâce à ses différents modes de transport efficaces et respectueux de l’environnement, qui relient les individus et les communautés. En 2023, présent dans 6 provinces et un territoire, Transdev Canada compte 4700 employés œuvrant dans les secteurs du transport urbain, scolaire, adapté et médical. Transdev est également l’exploitant du futur tramway Hurontario dans la région du Grand Toronto, en tant que membre du consortium Mobilinx, et du futur métro Ontario Line en tant que membre du Connect 6ix.`
								: `As a global mobility operator and integrator,
							Transdev allows everyone to get around every day
							thanks to safe, efficient and innovative solutions
							that serve the common good. Transdev carries an
							average of 11 million passengers every day thanks to
							its various efficient and environmentally-friendly
							modes of transport, which connect individuals and
							communities. In 2023, present in 6 Provinces and one
							Territory, Transdev Canada has 4700 employees
							operating in the urban, school, paratransit and
							medical transportation sectors. Transdev is also the
							operator of the future Hurontario tramway in the
							Greater Toronto Area, as a member of the Mobilinx
							consortium, and of the future Ontario Line subway as
							a member of the Connect 6ix.`}
						</p>
					</div>
					<AboutUs />
				</div>
			</section>
			<ContactUs />
		</Layout>
	);
};

export default SearchPage;
